exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insurance-and-financing-tsx": () => import("./../../../src/pages/insurance-and-financing.tsx" /* webpackChunkName: "component---src-pages-insurance-and-financing-tsx" */),
  "component---src-pages-membership-plans-tsx": () => import("./../../../src/pages/membership-plans.tsx" /* webpackChunkName: "component---src-pages-membership-plans-tsx" */),
  "component---src-pages-new-patient-special-tsx": () => import("./../../../src/pages/new-patient-special.tsx" /* webpackChunkName: "component---src-pages-new-patient-special-tsx" */),
  "component---src-pages-our-difference-tsx": () => import("./../../../src/pages/our-difference.tsx" /* webpackChunkName: "component---src-pages-our-difference-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-vip-list-tsx": () => import("./../../../src/pages/vip-list.tsx" /* webpackChunkName: "component---src-pages-vip-list-tsx" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-service-details-js": () => import("./../../../src/templates/service-details.js" /* webpackChunkName: "component---src-templates-service-details-js" */)
}

